<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Nature relation de travail" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une nature de relation de travail</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">type contrat</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le code dsn -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Code DSN</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le code DSN"
                    dense
                    v-model="dsnid"
                    :error-messages="dsniderror"
                    @keyup="changedsnlabel"
                    @change="changedsnlabel"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Libellé DSN</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le libellé DSN"
                    dense
                    v-model="dsnlabel"
                    @change="changedsnlabel"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { WorkRelationshipTypesService } from "@/service/user/work_relationship_type_service.js";
import * as stringTools from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddWorkRelationshipType",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /** le nom */
      label: null,
      dsnid: null,
      dsnlabel: null,
      dsniderror: null,
      dsnmapping: null,
    };
  },
  methods: {
    async changedsnlabel() {
      if (!stringTools.isNullOrEmpty(this.dsnid)) {
        const mappingfound = this.dsnmapping.find(
          (map) => map.dsnid === this.dsnid
        );
        this.dsniderror = mappingfound
          ? "Le code DSN est déja attribué à " +
            mappingfound.workrealtiontypelabel
          : "";
      } else {
        this.dsniderror =
          !stringTools.isNullOrEmpty(this.dsnid) ||
          (stringTools.isNullOrEmpty(this.dsnid) &&
            stringTools.isNullOrEmpty(this.dsnlabel))
            ? ""
            : "Le mapping DSN doit inclure un code";
      }
    },

    async load() {
      try {
        this.loading = true;
        this.dsnmapping = await this.service.getMappingForDsn();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde des données */

    async save() {
      // enregistrement
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.label = this.label;

          // création de la nature de relation de travail
          let newentity = await this.service.create(entity);
          if (this.dsnid) {
            try {
              await this.service.createDsnForId(
                newentity.id,
                this.service.buildDsnEntity(this.dsnid, this.dsnlabel)
              );
              this.$router.go(-1);
            } catch (errorDsn) {
              //En cas d'erreur dsn, on va effacer la potentielle entité relation de travail existante.
              //console.error("Erreur sur l'ajout de la donnée dsn. Tentative de suppression de la nature relation de travail.")
              try {
                await this.service.delete(newentity.id);
                this.addErrorToSnackbar(
                  "impossible d'enregistrer la nature de relation de travail : " +
                    (exceptions.toMessage(errorDsn) || "problème technique")
                );
              } catch (errorRestore) {
                console.error(
                  "Impossible de supprimer la nature relation de travail: " +
                    newentity.id +
                    ", SI potentiellement en état incoherent."
                );
                this.addErrorToSnackbar(
                  "impossible d'enregistrer la partie DSN de la nature de relation de travail : " +
                    (exceptions.toMessage(errorDsn) || "problème technique")
                );
              }
            }
          }
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la nature de relation de travail : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.label || this.label == "") {
          return false;
        }

        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.label) {
        changed = true;
      }

      if (
        !stringTools.isNullOrEmpty(this.dsnid) &&
        this.dsnmapping.find((map) => map.dsnid === this.dsnid)
      ) {
        changed = false;
      }

      return changed;
    },
  }, // END COMPUTED

  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new WorkRelationshipTypesService(
      this.$api.getWorkRelationshipTypeApi()
    );

    this.load();
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);
  },
};
</script>

<style></style>
